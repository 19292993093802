import { ApolloProvider } from '@apollo/client';
import { config, library } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import {
  faAngleDown,
  faAngleUp,
  faBars,
  faChartPie,
  faFile,
  faFilePdf,
  faSpinner,
  faTrash,
  faUser
} from '@fortawesome/free-solid-svg-icons';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { getApps, initializeApp } from 'firebase/app';
import 'font-awesome/css/font-awesome.min.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import { AppProps } from 'next/app';
import React, { useEffect } from 'react';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-dates/lib/css/_datepicker.css';
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import 'react-rangeslider/lib/index.css';
import { IntercomProvider } from 'react-use-intercom';
import apolloGraphClient from '../apollo/apolloClient';
import '../assets/css/drawer.css';
import '../assets/css/selectSearch.css';
import '../assets/css/style.css';
import '../assets/css/weather-icons.min.css';
import OverlayLoading from '../components/common/OverlayLoading';
import Meta from '../components/Meta';
import Page from '../components/Page';
import { AppProviders } from '../context';

//query client for react-query
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

config.autoAddCss = false;

library.add(
  faBars,
  faUser,
  faSpinner,
  faAngleDown,
  faAngleUp,
  faFilePdf,
  faTrash,
  faChartPie,
  faFile
);

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
};

const INTERCOM_APP_ID = 'ku329ebs';

function MyApp({ Component, pageProps }: AppProps): JSX.Element {
  useEffect(() => {
    if (!getApps().length) {
      initializeApp(firebaseConfig);
    }
  });
  if (!getApps().length) {
    return <OverlayLoading />;
  }
  return (
    <IntercomProvider appId={INTERCOM_APP_ID} autoBoot>
      <Meta />
      <QueryClientProvider client={queryClient}>
        <AppProviders>
          <ApolloProvider client={apolloGraphClient}>
            <Page>
              <Component {...pageProps} />
            </Page>
          </ApolloProvider>
        </AppProviders>
      </QueryClientProvider>
    </IntercomProvider>
  );
}
export default MyApp;
